import LogoDarkIcon from '@/assets/svg/logo-dark.svg';
import LogoIcon from '@/assets/svg/logo.svg';
import { mediaQueries } from '@/constants/mediaQuery';
import useMediaQuery from '@/hooks/useMediaQuery';
import { Bars2Icon, DevicePhoneMobileIcon, EnvelopeIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link } from 'gatsby';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import xhook from 'xhook';
import { StaticImage } from 'gatsby-plugin-image';

const MainLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
  const ref = useRef<any>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const isTabletAndDown = useMediaQuery(mediaQueries.isTabletAndDown);

  useEffect(() => {
    const scrollEl = ref.current.getScrollElement();
    scrollEl.scrollTop = 0;
  });

  useEffect(() => {
    // hook to track email sign up
    xhook.after((request, response) => {
      if (request.url.match(/www\.vbt\.io\/embedcode\/submit/)) {
        const data = JSON.parse(response.text);
        if (data.status == 'success') {
          // EVENT TRACKING
          (window as any).dataLayer = (window as any).dataLayer || [];
          (window as any).dataLayer.push({
            event: 'emailSignUp',
            formName: 'Email_Sign_Up',
          });
        }
      }
    });
  }, []);

  return (
    <>
      <div className="h-screen main-layout">
        <SimpleBar ref={ref} className="h-full">
          <div className="flex flex-col min-h-screen home-page w-full">
            <header className="bg-black sticky top-0 z-50">
              <nav className="flex items-center justify-between flex-wrap py-3 lg:py-6 px-4 lg:px-8 max-w-7xl m-auto z-10">
                <div className="flex flex-row divide-x divide-gray-300">
                  <div className="flex items-center flex-shrink-0 text-white mr-8">
                    <Link to="/" hrefLang="en" lang="en" rel="alternate">
                      <LogoIcon className="h-10" />
                    </Link>
                  </div>
                  <div className="hidden lg:flex menu">
                    <div className="flex items-center align-items-center">
                      <Link
                        to="/features"
                        activeClassName="active"
                        partiallyActive
                        className="menu-item ml-8 mr-8"
                        hrefLang="en"
                        lang="en"
                        rel="alternate"
                      >
                        Features
                      </Link>
                      <Link
                        to="/pricing"
                        activeClassName="active"
                        className="menu-item mr-8"
                        hrefLang="en"
                        lang="en"
                        rel="alternate"
                      >
                        Pricing
                      </Link>
                      <Link
                        to="/contact"
                        activeClassName="active"
                        className="menu-item"
                        hrefLang="en"
                        lang="en"
                        rel="alternate"
                      >
                        Contact
                      </Link>
                    </div>
                  </div>
                </div>
                {!isTabletAndDown && (
                  <div className="lg:flex hidden">
                    <Link to="/signup" className="ml-3" hrefLang="en" lang="en" rel="alternate">
                      <button className="btn-primary">Free Trial</button>
                    </Link>
                  </div>
                )}
                {isTabletAndDown && (
                  <div className="flex lg:hidden">
                    <button className="flex items-center px-3 py-2" onClick={() => setMenuOpen(true)}>
                      <Bars2Icon className="h-6 w-6 text-white"></Bars2Icon>
                    </button>
                  </div>
                )}
              </nav>
            </header>
            {isTabletAndDown && (
              <div
                className={`${
                  menuOpen ? 'translate-x-0' : 'translate-x-full'
                } block lg:hidden fixed top-0 right-0 h-screen w-screen z-50 transform transition-transform ease-in-out duration-300 bg-black`}
              >
                <header className="bg-black">
                  <nav className="flex items-center justify-between flex-wrap py-3 lg:py-6 px-4 lg:px-8 max-w-7xl m-auto z-10">
                    <div className="flex flex-row divide-x divide-gray-300">
                      <div className="flex items-center flex-shrink-0 text-white mr-8">
                        <Link to="/" hrefLang="en" lang="en" rel="alternate">
                          <LogoIcon className="h-10" />
                        </Link>
                      </div>
                    </div>
                    <div className="flex">
                      <button className="flex items-center px-3 py-2" onClick={() => setMenuOpen(false)}>
                        <XMarkIcon className="h-6 w-6 text-white"></XMarkIcon>
                      </button>
                    </div>
                  </nav>
                </header>
                <div className="menu h-full py-16 px-10 flex flex-col justify-between">
                  <div className="flex flex-col items-start align-items-center">
                    <Link
                      onClick={() => setMenuOpen(false)}
                      to="/"
                      activeClassName="active"
                      className="menu-item !text-2xl mb-9"
                      hrefLang="en"
                      lang="en"
                      rel="alternate"
                    >
                      Home
                    </Link>
                    <Link
                      onClick={() => setMenuOpen(false)}
                      to="/features"
                      activeClassName="active"
                      className="menu-item !text-2xl mb-9"
                      hrefLang="en"
                      lang="en"
                      rel="alternate"
                    >
                      Features
                    </Link>
                    <Link
                      onClick={() => setMenuOpen(false)}
                      to="/pricing"
                      activeClassName="active"
                      className="menu-item !text-2xl mb-9"
                      hrefLang="en"
                      lang="en"
                      rel="alternate"
                    >
                      Pricing
                    </Link>
                    <Link
                      onClick={() => setMenuOpen(false)}
                      to="/contact"
                      activeClassName="active"
                      className="menu-item !text-2xl mb-9"
                      hrefLang="en"
                      lang="en"
                      rel="alternate"
                    >
                      Contact
                    </Link>
                  </div>
                  <div className="flex mb-10 justify-center">
                    <Link to="/signup" className="ml-3" hrefLang="en" lang="en" rel="alternate">
                      <button className="ml-3 btn-primary">Free Trial</button>
                    </Link>
                  </div>
                </div>
              </div>
            )}
            <main className="flex w-full flex-col">{children}</main>
            <footer className="bg-gray-100">
              <div className="container !flex-col !pb-0 !pt-8">
                <div className="flex flex-col lg:flex-row flex-1">
                  <div className="basis-1/2 mb-12 lg:mb-0">
                    <div className="mb-8">
                      <Link to="/" hrefLang="en" lang="en" rel="alternate">
                        <LogoDarkIcon className="h-14 hover:cursor-pointer" />
                      </Link>

                      <h6 className="text-sm font-semibold text-gray-600">An LMS Designed for RTOs</h6>
                    </div>
                    <ul className="text-base text-gray-500">
                      <li className="flex items-center mb-6">
                        <DevicePhoneMobileIcon className="w-5 h-5" />

                        <Link to="tel:1800841669" className="ml-3" hrefLang="en" lang="en" rel="alternate">
                          1800 841 669
                        </Link>
                      </li>
                      <li className="flex items-center mb-6">
                        <EnvelopeIcon className="w-5 h-5 stroke-gray-500" />

                        <Link to="mailto:support@nimbu.au" className="ml-3" hrefLang="en" lang="en" rel="alternate">
                          support@nimbu.au
                        </Link>
                      </li>
                    </ul>

                    <div className="flex items-center space-x-2 mb-6">
                      <a
                        className="block w-8 h-8 cursor-pointer"
                        href="https://www.facebook.com/nimbu.au/"
                        target="blank"
                      >
                        <StaticImage alt="facebook" src="../assets/images/facebook.png" />
                      </a>
                      <a
                        className="block w-8 h-8 cursor-pointer"
                        href="https://www.linkedin.com/company/nimbu-lms/about/"
                        target="blank"
                      >
                        <StaticImage alt="linkedin" src="../assets/images/linkedin.png" />
                      </a>
                      <a
                        className="block w-8 h-8 cursor-pointer"
                        href="https://www.youtube.com/@nimbu_lms"
                        target="blank"
                      >
                        <StaticImage alt="youtube" src="../assets/images/youtube.png" />
                      </a>
                      <a
                        className="block w-8 h-8 cursor-pointer"
                        href="https://www.instagram.com/nimbulms/"
                        target="blank"
                      >
                        <StaticImage alt="instagram" src="../assets/images/instagram.png" />
                      </a>
                    </div>

                    <form
                      className="vbout-email-form"
                      id="vboutEmbedForm-103492"
                      name="vboutEmbedForm-103492"
                      method="POST"
                      action="https://www.vbt.io/embedcode/submit/103492/?_format=page"
                      target="_blank"
                      data-vboutform="103492"
                      encType="multipart/form-data"
                      data-netlify="true"
                      netlify-honeypot="bot-field"
                    >
                      <div className="flex flex-row items-center vbout-input-wrapper lg:pr-10 mb-2">
                        <div className="relative flex-1 h-fit vbout-input-inner">
                          <EnvelopeIcon className="absolute top-5 left-4 w-5 h-5 stroke-gray-500" />
                          <input
                            className="w-full border rounded-0 rounded-tl-lg rounded-bl-lg pl-[44px] p-4 !outline-none !ring-0 !border-gray-300"
                            placeholder="Enter your email"
                            name="vbout_EmbedForm[field][78980]"
                            id="custom-78980"
                          />
                        </div>
                        <button
                          type="submit"
                          className="bg-black h-fit p-4 text-white rounded-tr-lg rounded-br-lg text-base font-medium text-nowrap hover:bg-gray-700"
                        >
                          Join Nimbu
                        </button>
                      </div>
                      <div id="vboutEmbedFormResponse-103492" style={{ display: 'none' }}></div>
                    </form>
                  </div>

                  <div className="basis-1/2 flex flex-row">
                    <div className="basis-1/3">
                      <ul className="footer-links">
                        <li className="uppercase">NIMBU</li>
                        <li>
                          <Link to="/about" hrefLang="en" lang="en" rel="alternate">
                            About
                          </Link>
                        </li>
                        <li>
                          <Link to="/features" hrefLang="en" lang="en" rel="alternate">
                            Features
                          </Link>
                        </li>
                        <li>
                          <Link to="/pricing" hrefLang="en" lang="en" rel="alternate">
                            Pricing
                          </Link>
                        </li>
                        <li>
                          <Link to="https://blog.nimbu.au" hrefLang="en" lang="en" rel="alternate">
                            News
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="basis-1/3">
                      <ul className="footer-links">
                        <li className="uppercase">SUPPORT</li>
                        <li>
                          <Link to="https://support.nimbu.au/" hrefLang="en" lang="en" rel="alternate">
                            Knowledge Base
                          </Link>
                        </li>
                        <li>
                          <Link to="/faqs" hrefLang="en" lang="en" rel="alternate">
                            FAQs
                          </Link>
                        </li>
                        <li>
                          <Link to="/contact" hrefLang="en" lang="en" rel="alternate">
                            Contact
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="basis-1/3">
                      <ul className="footer-links">
                        <li className="uppercase">Terms</li>
                        <li>
                          <Link to="/privacy-policy" hrefLang="en" lang="en" rel="alternate">
                            Privacy Policy
                          </Link>
                        </li>
                        <li>
                          <Link to="/terms" hrefLang="en" lang="en" rel="alternate">
                            T&Cs
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="h-[60px] flex justify-center items-center text-gray-600 text-base">
                  © {new Date().getFullYear()} Nimbu, Inc. All rights reserved.
                </div>
              </div>
            </footer>
          </div>
        </SimpleBar>
      </div>
      <Helmet>
        <script async src="https://www.vbt.io/ext/vbtforms.js?lang=en" charSet="utf-8"></script>
      </Helmet>
    </>
  );
};

export default MainLayout;
