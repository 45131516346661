import React, { createContext, useContext, useState } from 'react';

export const SignUpContext = createContext({
  isLoading: false,
  setIsLoading: (state: boolean) => {},
  domainUrl: '',
  setDomainUrl: (state: string) => {},
});

export const SignUpProvider: React.FC<{
  children: JSX.Element | JSX.Element[];
}> = ({ children }) => {
  const [domainUrl, setDomainUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <SignUpContext.Provider value={{ domainUrl, setDomainUrl, isLoading, setIsLoading }}>
      {children}
    </SignUpContext.Provider>
  );
};

export const useSignUpContext = () => useContext(SignUpContext);
