exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-early-bird-index-tsx": () => import("./../../../src/pages/early-bird/index.tsx" /* webpackChunkName: "component---src-pages-early-bird-index-tsx" */),
  "component---src-pages-faqs-general-tsx": () => import("./../../../src/pages/faqs/general.tsx" /* webpackChunkName: "component---src-pages-faqs-general-tsx" */),
  "component---src-pages-faqs-index-tsx": () => import("./../../../src/pages/faqs/index.tsx" /* webpackChunkName: "component---src-pages-faqs-index-tsx" */),
  "component---src-pages-faqs-nimbu-features-tsx": () => import("./../../../src/pages/faqs/nimbu-features.tsx" /* webpackChunkName: "component---src-pages-faqs-nimbu-features-tsx" */),
  "component---src-pages-faqs-pricing-plan-tsx": () => import("./../../../src/pages/faqs/pricing-plan.tsx" /* webpackChunkName: "component---src-pages-faqs-pricing-plan-tsx" */),
  "component---src-pages-features-avetmiss-reporting-tsx": () => import("./../../../src/pages/features/avetmiss-reporting.tsx" /* webpackChunkName: "component---src-pages-features-avetmiss-reporting-tsx" */),
  "component---src-pages-features-class-management-tsx": () => import("./../../../src/pages/features/class-management.tsx" /* webpackChunkName: "component---src-pages-features-class-management-tsx" */),
  "component---src-pages-features-content-creation-tsx": () => import("./../../../src/pages/features/content-creation.tsx" /* webpackChunkName: "component---src-pages-features-content-creation-tsx" */),
  "component---src-pages-features-index-tsx": () => import("./../../../src/pages/features/index.tsx" /* webpackChunkName: "component---src-pages-features-index-tsx" */),
  "component---src-pages-features-online-assessments-tsx": () => import("./../../../src/pages/features/online-assessments.tsx" /* webpackChunkName: "component---src-pages-features-online-assessments-tsx" */),
  "component---src-pages-features-practical-observations-tsx": () => import("./../../../src/pages/features/practical-observations.tsx" /* webpackChunkName: "component---src-pages-features-practical-observations-tsx" */),
  "component---src-pages-features-rto-customisation-tsx": () => import("./../../../src/pages/features/rto-customisation.tsx" /* webpackChunkName: "component---src-pages-features-rto-customisation-tsx" */),
  "component---src-pages-features-student-experience-tsx": () => import("./../../../src/pages/features/student-experience.tsx" /* webpackChunkName: "component---src-pages-features-student-experience-tsx" */),
  "component---src-pages-features-trainer-interface-tsx": () => import("./../../../src/pages/features/trainer-interface.tsx" /* webpackChunkName: "component---src-pages-features-trainer-interface-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-index-tsx": () => import("./../../../src/pages/pricing/index.tsx" /* webpackChunkName: "component---src-pages-pricing-index-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-signup-index-tsx": () => import("./../../../src/pages/signup/index.tsx" /* webpackChunkName: "component---src-pages-signup-index-tsx" */),
  "component---src-pages-terms-index-tsx": () => import("./../../../src/pages/terms/index.tsx" /* webpackChunkName: "component---src-pages-terms-index-tsx" */)
}

