import { ReactNode } from 'react';
import AuthLayout from './auth';
import MainLayout from './default';
import React from 'react';

export default ({ children, pageContext }: { children: ReactNode; pageContext: any }) => {
  if (pageContext.layout === 'auth') {
    return <AuthLayout>{children}</AuthLayout>;
  }
  if (pageContext.layout === 'none') {
    return <>{children}</>;
  }
  return <MainLayout pageContext={pageContext}>{children}</MainLayout>;
};
