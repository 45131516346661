import * as React from 'react';
import WrapRootElement from './src/wrap-root-element';
import WrapPageElement from './src/wrap-root-element';
import './src/assets/css/global.scss';

export const wrapRootElement = ({ element }) => <WrapRootElement>{element}</WrapRootElement>;

export const wrapPageElement = ({ element, props }) => {
  <WrapPageElement {...props}>{element}</WrapPageElement>;
};
