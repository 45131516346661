import Spinner from '@/components/share/Spinner';
import React, { Fragment, ReactNode } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import './auth.scss';

const AuthLayout: React.FC<{ isLoading?: boolean; children: ReactNode }> = ({ children, isLoading }) => {
  return (
    <Fragment>
      {isLoading && <Spinner className="absolute top-1/2 left-1/2 z-20" />}
      {isLoading && (
        <div className="fixed top-0 bottom-0 left-0 right-0 bg-zinc-900 opacity-10 z-10 pointer-events-none"></div>
      )}
      <div className="h-screen">
        <SimpleBar className="h-full">
          <div className={`w-screen min-h-screen bg-gradient flex`}>
            <div className="flex-1 flex flex-col">{children}</div>
          </div>
        </SimpleBar>
      </div>
    </Fragment>
  );
};

export default AuthLayout;
